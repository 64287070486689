<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>SKU Configurations</h3>
    </header>
    <sku-configurations></sku-configurations>
    <client-exceptions :tagModule="'SKU_EXCEPTIONS'"></client-exceptions>
  </div>
</template>

<script>
import SKUConfiguration from '../../../components/OrderManagement/SkuConfigs/SKUConfigurations.vue';
import ClientExceptions from '../../../components/OrderManagement/SkuConfigs/ClientExceptions.vue';

export default {
  name: 'Skuconfigs.vue',
  components: {
    'sku-configurations': SKUConfiguration,
    'client-exceptions': ClientExceptions,
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
